import "./Footer.scss";
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import logoFooter from "../../styles/images/silla-bookolicos-blanco.svg";
import Icon from "../Icon/Icon";
import InputText from "../DesingSystem/InputText/InputText";

function Footer() {
  const navigate = useNavigate();
  const [isTextEntered, setIsTextEntered] = useState(false);

  const handleInputTextFocus = () => {
    setIsTextEntered(true);
  };
  const handleInputTextBlur = () => {
    setIsTextEntered(false);
  };

  const navigateToPath = (path) => {
    navigate(path);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.currentTarget.value !== '') {
      navigate('buscador/?keyword=' + event.currentTarget.value)
    }
  }

  const goToSearch = () => {
    navigate('buscador/?keyword=' + document.getElementById("text").value)
  }

  return (
    <>
      <section className="footer">
        <div id="logo" className="footer__section-logo">
          <img src={logoFooter} alt="Logo Bookolicos text"></img>
        </div>

        <div id="newsletter" className="footer__section-newsletter">
          <div className="footer__section-newsletter-input">
            <p className="mb-0 d-md-none">¿Buscabas algo?</p>
            <InputText
              icon="search"
              iconSize="large"
              iconColor="secondary-7"
              iconBorder="background-2"
              error={isTextEntered}
              onFocus={handleInputTextFocus}
              onBlur={handleInputTextBlur}
              type="text"
              id="text"
              name="text"
              placeholder="Busca obras, autores, géneros..."
              onKeyDown={handleKeyDown}
              handleIconClick={goToSearch}
            />
          </div>
        </div>

        <div id="input" className="footer__section-input">
          <div className="footer__section-input-up">
            <p
              className="footer__section-input-text"
              onClick={() => navigateToPath('/book-list')}
            >
              Catálogo
            </p>
            <p className="footer__section-input-text" onClick={() => navigateToPath('/publica-tu-obra')}>
              Publica tu obra
            </p>
            <p className="footer__section-input-text" onClick={() => navigateToPath('/blog')}>
              Blog
            </p>
            <p
              className="footer__section-input-text"
              onClick={() => navigateToPath('/preguntas-generales')}
            >
              FAQs
            </p>
            <p
              className="footer__section-input-text"
              onClick={() => navigateToPath('/contacta-con-nosotros')}
            >
              Contacto
            </p>
          </div>
          {/*<p className="footer__section-input-text" onClick={gotToNews}>
              Noticias{" "}
              </p>*/}
          {/* <p
              className="footer__section-input-text"
              onClick={gotToBlog}
            >
              Blog
            </p>*/}
          <div className="footer__section-input-down">
          </div>
        </div>

        <div id="rrss" className="footer__section-rrss">
          <ul className="footer__section-rrss-list">
            {/*<li className="footer__section-rrss-list-item">
              <a href="#" target="_blank">
                <Icon
                  name="facebook"
                  size="medium2"
                  color="secondary-1"
                  background="1"
                />
              </a>
            </li>*/}
            <li
              className="footer__section-rrss-list-item"
            >
              <a href="https://instagram.com/bookolicos.es" target="_blank">
                <Icon
                  name="instagram"
                  size="medium2"
                  color="secondary-1"
                  background="1"
                />
              </a>
            </li>
            <li
              className="footer__section-rrss-list-item"
            >
              <a href="https://www.linkedin.com/company/bookólicos/" target="_blank">
                <Icon
                  name="linkedin"
                  size="medium2"
                  color="secondary-1"
                  background="1"
                />
              </a>
            </li>
            {/*<li className="footer__section-rrss-list-item">
              <a href="#" target="_blank">
                <Icon
                  name="twitter"
                  size="medium2"
                  color="secondary-1"
                  background="1"
                />
              </a>
            </li>*/}
          </ul>
        </div>

        <div id="copy" className="footer__section-copy">
          <small className="footer__section-copy-text">
            © {new Date().getFullYear()} Bookólicos
          </small>
        </div>

        <div id="legality" className="footer__section-legality">
          <Link to="/politica-privacidad" target="_blank">
            <p className="footer__section-legality-text">
              Política de privacidad
            </p>
          </Link>
          <Link to="/aviso-legal" target="_blank">
            <p className="footer__section-legality-text">Aviso legal</p>
          </Link>
          <Link to="/condiciones-generales" target="_blank">
            <p className="footer__section-legality-text">Condiciones de compra</p>
          </Link>
          <Link to="/terminos-condiciones" target="_blank">
            <p className="footer__section-legality-text">
              Términos y condiciones
            </p>
          </Link>
          <Link to="/seguridad-producto-bookolicos.txt" target="_blank">
            <p className="footer__section-legality-text">
              Seguridad de producto
            </p>
          </Link>
        </div>
        <div className="footer__section-cards">
          <img src="/images/medios-pago.svg" alt="cards" />
          <img src="/images/logos-kitdigital-bookolicos.jpg" class="footer__section-cards__kit-digital" alt="logos kit digital" />
        </div>
      </section>

    </>
  );
}

export default Footer;
